import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import SliderSlick from "../shared/top/Slider"

const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "noone/slider" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(width: 1000, height: 800)
          }
        }
      }
    }
  }
`

const TopSlider = () => {
  const data = useStaticQuery(query)
  const items = data.allFile.edges.map((item, index) => {
    return {
      title: `noone image ${index + 1}`,
      image: getImage(item.node.childrenImageSharp[0].gatsbyImageData),
    }
  })

  return <SliderSlick items={items} target="noone" />
}

export default TopSlider
