import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/noone/Layout"
import TopContact from "../../components/noone/TopContact"
import TopFeature from "../../components/noone/TopFeature"
import TopInstagram from "../../components/noone/TopInstagram"
import TopPartners from "../../components/noone/TopPartners"
import TopSlider from "../../components/noone/TopSlider"
import SEO from "../../components/Seo"
import About from "../../components/shared/top/About"
import Concept from "../../components/shared/top/Concept"
import OpeningShutter from "../../components/shared/top/OpeningShutter"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "noone/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const index = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <SEO siteTitle="noone" />
      <OpeningShutter logo={getImage(data.logo)} target="noone" />
      <TopSlider />
      <Concept name="noone" bgDotes="md:bg-nooneFourDots" />
      <About target="noone" />
      <TopFeature />
      <TopInstagram />
      <TopPartners />
      <TopContact />
    </Layout>
  )
}

export default index
